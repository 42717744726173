import { Switch, Route, Link } from "react-router-dom";

import Home from "./App/home";
import Terms from "./App/terms";
import Privacy from "./App/privacy";
import Cookie from "./App/cookie";
import Disclaimer from "./App/disclaimer";


function MainApp() {
  return (
    <Switch>
      <Route exact path='/' component={Home} />
      <Route path='/terms' component={Terms} />
      <Route path='/privacy-policy' component={Privacy} />
      <Route path='/cookie-policy' component={Cookie} />
      <Route path='/disclaimer' component={Disclaimer} />
    </Switch>
  );
}

export default MainApp;
  