var date = new Date().getFullYear();

function Footer() {
    return (
      <footer class="sh_footer_email margin-t-12 padding-b-8">
        <div class="container">
          {/* <div class="row">
            <div class="col-lg-6">
              <div class="email_logo">
                <div class="media">
                  <a href="mobile-app-2.html" class="brand_logo my-auto">
                    <img src="./assets/img/logo.svg"  style = {{width: 87}} alt=""/>
                   </a>
                  <div class="media-body my-auto">
                    <div class="txt">
                      <span>Neet help? Contact us at</span>
                      <a href="mailto:hey@watever.co">
                        hey@watever.co
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div class="dividar"></div> */}
          <div class="row">
            <div class="col-md-5 col-lg-4">
              <div class="copyright">
                <p>
                  © {`${date} `}
                  <a href="/">Cybernate. </a>
                  All Right Reseved
                </p>
              </div>
            </div>
            <div class="col-md-7 col-lg-8">
              <ul class="nav justify-content-md-end">
                <li class="nav-item">
                  <a class="nav-link" href="mailto:hey@whateva.co">Support</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/terms">Terms of Use</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/privacy-policy">Privacy Policy</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/cookie-policy">Cookie Policy</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/disclaimer">Disclaimer</a>
                </li>

              </ul>
            </div>
          </div>
        </div>
      </footer>
      
    );
  }
  
  export default Footer;
  