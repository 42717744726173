import Header from "../../../components/header"
import Footer from "../../../components/footer"
import Help from "../../../components/help"

function Disclaimer() {
  return (
    <div id="wrapper">
    <div id="content">

      <Header/>


      <main data-spy="scroll" data-target="#navbar-example2" data-offset="0">
      <section class="pt_banner_inner banner_Sblog_default">
          <div class="container">
            <div class="row justify-content-center text-center">
              <div class="col-md-8 col-lg-7 my-auto">
                <div class="banner_title_inner margin-b-8">
                  {/* <div class="icon_c six">
                    <svg id="Stockholm-icons-_-Home-_-Deer" data-name="Stockholm-icons-/-Home-/-Deer" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <rect id="bound" width="24" height="24" fill="none"></rect>
                      <path id="Combined-Shape" d="M21.982,8.189a.993.993,0,0,1-.467.668l-5,3A1,1,0,0,1,16,12H8a1,1,0,0,1-.514-.143l-5-3a.993.993,0,0,1-.467-.668l-1-4.993A1,1,0,0,1,2.981,2.8l.634,3.168L6.293,3.293A1,1,0,0,1,7.707,4.707L4.613,7.8,8.277,10h7.446l3.664-2.2L16.293,4.707a1,1,0,0,1,1.414-1.414l2.679,2.679L21.019,2.8a1,1,0,0,1,1.961.392Zm-6.929.705a1,1,0,1,1,.894-1.789l3,1.5a1,1,0,0,1,.067,1.752l-2.5,1.5A1,1,0,0,1,16,12H8a1,1,0,0,1-.514-.143l-2.5-1.5a1,1,0,0,1,0-1.715l2.5-1.5A1,1,0,1,1,8.514,8.857L7.444,9.5l.833.5h7.446l.7-.42Z" fill="#fff" opacity="0.3"></path>
                      <path id="Rectangle-192" d="M9.855,10h4.289a2,2,0,0,1,1.88,2.683L13.342,20.06A1.428,1.428,0,0,1,12,21h0a1.428,1.428,0,0,1-1.342-.94L7.976,12.683A2,2,0,0,1,9.855,10Z" fill="#fff" fill-rule="evenodd"></path>
                    </svg>
                  </div> */}
                  <h1>
                    Watever's Disclaimer
                  </h1>
                </div>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb default justify-content-center">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item"><a href="/">Legal</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Disclaimer</li>
                  </ol>
                </nav>

              </div>
            </div>

            <div class="row">
              <div class="col-lg-12">
                <div class="cover_Sblog">
                  {/* <div class="simpleParallax" style="overflow: hidden;"><img class="cover-parallax" src="../../assets/img/inner/04984.jpg" alt="" style="transform: translate3d(0px, 41px, 0px) scale(1.3); will-change: transform; transition: transform 0.6s cubic-bezier(0, 0, 0, 1) 0s;"></div> */}
                </div>
              </div>
            </div>
          </div>
        </section>


       
        <section class="content-Sblog" data-sticky-container>
          <div class="container">
            <div class="row">
              <div class="col-lg-3">
                <div class="fixSide_scroll" data-sticky-for="1023" data-margin-top="100">
                  <div class="item">
                    <div class="profile_user">
                      <div class="txt">
                        <h4>
                        Cybernate
                        </h4>
                        <time>1 December, 2023</time>
                      </div>
                    </div>
                  </div>
                  <div class="share_socail">
                    <div class="title">Share</div>

                    <button class="btn icon" data-toggle="tooltip" data-placement="right" title="Facebook"
                      data-sharer="facebook" data-hashtag="Watever"
                      data-url="/">
                      <i class="tio facebook"></i>
                    </button>

                    <button class="btn icon" data-toggle="tooltip" data-placement="right" title="Twitter"
                      data-sharer="twitter" data-title="Checkout Watever!" data-hashtags="Watever, app"
                      data-url="/">
                      <i class="tio twitter"></i>
                    </button>

                    {/* <button class="btn icon" data-toggle="tooltip" data-placement="right" title="Whatsapp"
                      data-sharer="whatsapp" data-title="Checkout Rakon!"
                      data-url="https://themeforest.net/item/rakon-html-landing-page-templates/27849521">
                      <i class="tio whatsapp_outlined"></i>
                    </button>

                    <button class="btn icon" data-toggle="tooltip" data-placement="right" title="Telegram"
                      data-sharer="telegram" data-title="Checkout Rakon!"
                      data-url="https://themeforest.net/item/rakon-html-landing-page-templates/27849521"
                      data-to="+44555-03564">
                      <i class="tio telegram"></i>
                    </button>

                    <button class="btn icon" data-toggle="tooltip" data-placement="right" title="Pinterest"
                      data-sharer="pinterest"
                      data-url="https://themeforest.net/item/rakon-html-landing-page-templates/27849521">
                      <i class="tio pinterest_circle"></i>
                    </button>

                    <button class="btn icon" data-toggle="tooltip" data-placement="right" title="skype"
                      data-sharer="skype"
                      data-url="https://themeforest.net/item/rakon-html-landing-page-templates/27849521"
                      data-title="Checkout Rakon!">
                      <i class="tio skype"></i>
                    </button> */}

                  </div>
                </div>
              </div>
              <div class="col-lg-8">
                <div class="body_content">

                <p>
                watever.co and our policies are provided with the purpose of helping you with compliance. While we do our best to provide you useful information to use as a starting point, nothing can substitute professional legal advice in drafting your legal policies and/or assisting you with compliance. We cannot guarantee any conformity with the law, which only a lawyer can do. We are not attorneys. We are not liable for any content, code, or other errors or omissions or inaccuracies. Our website provides no warranties or guarantees. Nothing in this website, therefore, shall be considered legal advice and no attorney-client relationship is established. Please note that in some cases, depending on your legislation, further actions may be required to make your business compliant with the law.
                </p>
                </div>
                </div>
                </div>
                </div>
               


        </section>
   
  

      </main>
  
   
    
    </div>


   <Footer/>
   </div>
    
  );
}

export default Disclaimer;
 