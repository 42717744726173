 import React, {useEffect, useState} from "react";
 import { Redirect, Switch, Route, useHistory } from "react-router-dom";
 import { shallowEqual, useSelector } from "react-redux";
//  import {Layout} from "../_metronic/layout";
 import MainApp from "./";

 export function RoutesStack() {
     const {isAuthorized} = useSelector(
         ({auth}) => ({
             isAuthorized: auth.user != null,
         }),
         shallowEqual
     );
 

     useEffect(() => {
      // getUserGeolocationDetails();
     }, [])
     

     return (
         <Switch>
            <MainApp/>
         </Switch>
     );
 }
 